import { Field, Formik } from 'formik';
import { flowRight } from 'lodash-es';
import PropTypes from 'prop-types';
import 'react-credit-cards/es/styles-compiled.css';
import { v4 as uuidv4 } from 'uuid';
import { FieldContainer, ServerErrors, withFormWrap } from '../../../../shared';
import { initialValues, schema, create } from './helpers';
import { useApolloClient } from '@apollo/client';

function BillingInvoicePostPaymentFormModal({
  modalOpen,
  closeModal,
  maxAmount,
  errorMessages,
  initialValues = () => {},
  schema = () => {},
  selectedItem,
  refetch,
  setErrorMessages,
  clearErrorMessages,
}) {
  const client = useApolloClient();
  const resetAndCloseModal = (resetForm) => {
    resetForm();
    closeModal();
  };
  function handleSubmit(values, { setSubmitting }) {
    const variables = {
      variables: { input: { input: values, clientMutationId: uuidv4() } },
    };
    create({
      variables,
      client,
      setErrorMessages,
      clearErrorMessages,
      refetch,
      closeModal,
    });
    setSubmitting(false);
  }
  return (
    <Formik
      initialValues={initialValues({ selectedItem })}
      validationSchema={schema({ maxAmount })}
      // enableReinitialize={true}
      onSubmit={handleSubmit}
    >
      {({ resetForm, handleSubmit: onSubmit, errors, touched }) => (
        <form noValidate onSubmit={onSubmit}>
          <div className={`modal ${modalOpen ? 'is-active' : ''}`}>
            <div className="modal-background" />
            <div className="modal-card">
              <header className="modal-card-head">
                <h4 className="title is-5 mb-0 ml-4 modal-card-title">
                  Post A Payment
                </h4>
              </header>
              <section className="modal-card-body">
                <div className="field is-grouped">
                  <div className="control is-expanded has-icons-left">
                    <FieldContainer
                      name="paymentAmount"
                      label="Payment Amount"
                      required
                    >
                      <div
                        className={`icon is-left has-text-white ${
                          errors.amount && touched.amount
                            ? 'has-background-danger'
                            : 'has-background-success'
                        }`}
                        style={{ width: 40, borderRadius: '10% 0 0 10%' }}
                      >
                        $
                      </div>
                      <Field
                        id="paymentAmount"
                        name="paymentAmount"
                        type="number"
                        step="0.01"
                        placeholder="0.00"
                        className="input pl-6"
                        max={maxAmount}
                      />
                    </FieldContainer>
                  </div>
                  <div className="control is-expanded">
                    <FieldContainer
                      name="cardBilled"
                      label="Payment Method Info"
                      required
                    >
                      <Field
                        id="cardBilled"
                        name="cardBilled"
                        required
                        placeholder="i.e. Check #1234"
                        className="input"
                      />
                    </FieldContainer>
                  </div>
                </div>
                <div className="field">
                  <div className="control">
                    <FieldContainer name="message" required>
                      <Field
                        id="message"
                        name="message"
                        component="textarea"
                        rows="2"
                        required
                        placeholder="Payment Memo"
                        className="textarea"
                      />
                    </FieldContainer>
                  </div>
                </div>
                <ServerErrors errorMessages={errorMessages} />
              </section>
              <footer className="modal-card-foot is-flex is-flex-direction-row is-justify-content-flex-end">
                <button
                  className="button is-dark"
                  type="button"
                  onClick={() => {
                    resetAndCloseModal(resetForm);
                  }}
                >
                  Close
                </button>
                <button className="button is-primary" type="submit">
                  Post Payment
                </button>
              </footer>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
}

BillingInvoicePostPaymentFormModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  errors: PropTypes.object,
  touched: PropTypes.object,
  submitForm: PropTypes.func.isRequired,
  errorMessages: PropTypes.array.isRequired,
  resetForm: PropTypes.func.isRequired,
  maxAmount: PropTypes.number.isRequired,
};

export default flowRight(withFormWrap({ initialValues, schema }))(
  BillingInvoicePostPaymentFormModal,
);
